<template>
  <!--footer-->
  <footer class="b-footer__wrapper">
    <div class="container">
      <div class="row just-sb">
        <div class="col-lg-2 col-md-auto">
          <a href="#" class="b-footer__logo">
            <img src="@/assets/img/logo.png" alt="" />
          </a>
        </div>
        <div class="order-lg-1 order-md-3 col-lg-10 col-sm-12">
          <ul class="b-footer__menu">
            <li><a href="#">Железное дело</a></li>
            <li><a href="#catalog">Выбор агрегата</a></li>
            <li><a href="#delivery">Доставка</a></li>
            <li><a href="#documents">Документы</a></li>
          </ul>
        </div>
        <div
          class="order-lg-1 order-md-4 order-sm-3 col-lg-auto col-md col-sm-12 align-se"
        >
          <div class="b-footer__copy">
            © 2023 «ЖЕЛЕЗНОЕ ДЕЛО»<br />Все права защищены
          </div>
        </div>
        <div
          class="order-lg-1 order-md-5 order-sm-2 col-md-auto col-sm-12 align-se"
        >
          <a href="/policy" class="b-footer__police"
            >Политика конфиденциальности</a
          >
        </div>
        <div class="order-md-1 order-sm-1 col-auto col-md-auto col-sm-12">
          <div class="b-footer__contacts">
            <a href="tel:+79195150777">+7 919 515 07 77</a>
            <span>Работаем в выходные<br />и праздники</span>
          </div>
        </div>
        <div class="order-md-6 order-sm-4 col-lg-12 col-sm-12">
          <div class="b-footer__small">
            <p>
              Осуществляем ремонт, исключительно оригинальных агрегатов,
              введенных в гражданский оборот с согласия правообладателей
              соответствующих марок. Указание на товарные знаки на страницах
              сайта осуществляется только применительно к агрегатам, уже
              введенным в гражданский оборот с согласия правообладателя.
            </p>
            <p>
              Исчерпание исключительного права на товарный знак: Не является
              нарушением исключительного права на товарный знак использование
              этого товарного знака другими лицами в отношении товаров, которые
              были введены в гражданский оборот на территории Российской
              Федерации непосредственно правообладателем или с его согласия.
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  setup() {},
};
</script>
