<template>
 <section class="b-catalog-wrapper g-wrapper" id="catalog">
    <div class="container">
      <div class="row">

        <div v-for="(product, i) in products" :key="i" class="col-lg-4 col-md-6 col-sm-12">
          <div class="b-catalog__item">
            <div class="b-catalog__item__img">
              <img :src="require(`@/assets/img/catalog/${product.img}`)" alt="" @contextmenu.prevent="event.preventDefault()">
            </div>
            <div class="b-catalog__item__description">
              <div class="b-catalog__item__title">{{ product.name }}</div>
              <div class="b-catalog__item__subtitle">{{ product.desc }}</div>
            </div>

            <ul class="b-catalog__item__actions">
              <li><a href="#" class="btn_white" @click.prevent="pop_up_open"><span>Обмен</span></a></li>
              <li><a href="#" class="btn_white" @click.prevent="pop_up_open"><span>Купить</span></a></li>
              <li><a href="#" class="btn_white" @click.prevent="pop_up_open"><span>Ремонт</span></a></li>
            </ul>
          </div>
        </div>

        <div class="col-lg-8 col-sm-12">
          <div class="b-catalog__bnr">
            <div class="b-catalog__bnr__icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="148" height="128" viewBox="0 0 148 128" fill="none">
                <path
                  d="M110.767 0.265626L147.553 63.9807L110.767 127.696H37.1951L0.40918 63.9807L37.1951 0.265625L110.767 0.265626Z"
                  fill="black" />
                <path d="M110.767 0.265626L37.1951 127.696L0.40918 63.9807L37.1951 0.265625L110.767 0.265626Z"
                  fill="#424653" />
                <path
                  d="M68.4365 25L65.6094 33.6396C61.3328 34.8191 57.4218 36.8734 54.0811 39.6045L56.0879 35.2422L52.5605 33.3232C51.392 32.6887 49.9458 32.8984 49.0068 33.8389L42.4883 40.3545L46.6074 48.4814C44.4997 52.1936 43.1079 56.3651 42.6611 60.8213L41 56.3301L37.1504 57.4697C35.8754 57.8462 35 59.0177 35 60.3467V69.5635L43.6396 72.3906C44.8191 76.6672 46.8734 80.5782 49.6045 83.9189L45.2422 81.9121L43.3232 85.4395C42.6887 86.608 42.8984 88.0542 43.8389 88.9932L50.3545 95.5117L58.4814 91.3926C62.1936 93.5003 66.3651 94.8922 70.8213 95.3389L66.3301 97L67.4697 100.85C67.8462 102.125 69.0177 103 70.3467 103H79.5635L82.3906 94.3604C86.6672 93.1809 90.5782 91.1266 93.9189 88.3955L91.9121 92.7578L95.4395 94.6768C96.608 95.3113 98.0542 95.1016 98.9932 94.1611L105.512 87.6455L101.393 79.5186C103.5 75.8064 104.892 71.6349 105.339 67.1787L107 71.6699L110.85 70.5303C112.125 70.1538 113 68.9823 113 67.6533V58.4365L104.36 55.6094C103.181 51.3328 101.127 47.4218 98.3955 44.0811L102.758 46.0879L104.677 42.5605C105.311 41.392 105.102 39.9458 104.161 39.0068L97.6455 32.4883L89.5186 36.6074C85.8064 34.4997 81.6349 33.1079 77.1787 32.6611L81.6699 31L80.5303 27.1504C80.1538 25.8754 78.9823 25 77.6533 25H68.4365ZM74 40C87.2555 40 98 50.7445 98 64C98 77.254 87.2555 88 74 88C60.7445 88 50 77.254 50 64C50 50.7445 60.7445 40 74 40ZM74 46C64.0595 46 56 54.0595 56 64C56 73.9405 64.0595 82 74 82C83.9405 82 92 73.9405 92 64C92 54.0595 83.9405 46 74 46ZM74 58C77.3135 58 80 60.6865 80 64C80 67.3135 77.3135 70 74 70C70.6865 70 68 67.3135 68 64C68 60.6865 70.6865 58 74 58Z"
                  fill="white" />
              </svg>
            </div>
            <div class="b-catalog__bnr__description">
              Только заводские оригинальные комплектующие и уплотнения собственного производства
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>


<script setup>

import { useStore } from 'vuex'
import { ref } from 'vue'

const store = useStore()

function pop_up_open(){
  store.dispatch('update_pop_up', true)
}


const products = ref([
    {
        name: "КПП К—700(А)",
        desc: "С внутренним масл. фильтром. №700А.17.00.000 серийная / реверсивная.",
        "img": "1.jpg",
    },
    {
        name: "КПП К—701",
        desc: "С выносным масл. фильтром. № 700А.17.00.000. серийная / реверсивная",
        "img": "2.jpg"
    },
    {
        name: "КПП К—702 УДМ",
        desc: "Для фронтальных погрузчиков ПК–6. № 2765015-17.00.000",
        "img": "3.jpg"
    },
    {
        name: "ГТР К—702 (гидротрансформатор)",
        desc: "Для фронтальных погрузчиков К—702 УДМ. № 276.5020.16.03.000.09",
        "img": "4.jpg"
    },
    {
        name: "КПП К—703",
        desc: "Для тракторов К-703 БКУ, АС-100. № 2256010-17.00.000",
        "img": "5.jpg"
    },
    {
        name: "КПП К—744 К—739 К—7",
        desc: "Для тракторов 2016 годов выпуска и позднее № 744-17.00.000",
        "img": "6.jpg"
    },
    {
        name: "КПП Т—150(К) Т—17221 ХТЗ",
        desc: "№ 151.37.005, № 151.37.001-8Р и модификации",
        "img": "7.jpg"
    },
    {
        name: "КПП Т—130/170",
        desc: "Для бульдозеров 24-12-1СП/50-12-8 и тихо/быстро–ходные, болотные",
        "img": "8.jpg"
    },
    {
        name: "ГМП Амкодор ТО—18, ТО—28",
        desc: "Коробка передач погрузчика У35.615-00.000 и модификации",
        "img": "9.jpg"
    },
    {
        name: "КПП Dressta L—34, L—35",
        desc: "Для погрузчика ГМП (коробка) Stalowa Wola SB-165. 325-04-0000",
        "img": "10.jpg"
    },
])


// const disableContextMenu = (event) => {
//   event.preventDefault();
// };

</script>

