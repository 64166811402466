<template>
  <article>
    <a href="/" aria-label="Назад на главную страницу">Назад</a>
    <h2>Политика конфиденциальности и защиты информации</h2>
    <p>
      Оставляя данные на сайте, Вы соглашаетесь с Политикой конфиденциальности и
      защиты информации
    </p>
    <h2>Защита данных</h2>
    <p>
      Индивидуальный предприниматель
      <b>Земцов Иван Геннадьевич ИНН 432101214436</b>
      (далее Сайт) не может передать или раскрыть информацию, предоставленную
      пользователем (далее Пользователь) при регистрации и использовании функций
      сайта третьим лицам, кроме случаев, описанных законодательством страны, на
      территории которой пользователь ведет свою деятельность.
    </p>
    <h2>Получение персональной информации</h2>
    <p>
      Для коммуникации на сайте пользователь обязан внести некоторую
      персональную информацию. Для проверки предоставленных данных, сайт
      оставляет за собой право потребовать доказательства идентичности в онлайн
      или офлайн режимах.
    </p>
    <h2>Использование персональной информации</h2>
    <p>
      Сайт использует личную информацию Пользователя для обслуживания и для
      улучшения качества предоставляемых услуг. Часть персональной информации
      может быть предоставлена банку или платежной системе, в случае, если
      предоставление этой информации обусловлено процедурой перевода средств
      платежной системе, услугами которой Пользователь желает воспользоваться.
      Сайт прилагает все усилия для сбережения в сохранности личных данных
      Пользователя. Личная информация может быть раскрыта в случаях, описанных
      законодательством, либо когда администрация сочтет подобные действия
      необходимыми для соблюдения юридической процедуры, судебного распоряжения
      или легального процесса необходимого для работы Пользователя с Сайтом. В
      других случаях, ни при каких условиях, информация, которую Пользователь
      передает Сайту, не будет раскрыта третьим лицам.
    </p>
    <h2>Коммуникация</h2>
    <p>
      После того, как Пользователь оставил данные, он получает сообщение,
      подтверждающее его успешную регистрацию. Пользователь имеет право в любой
      момент прекратить получение информационных бюллетеней воспользовавшись
      соответствующим сервисом в Сайте.
    </p>
    <h2>Ссылки</h2>
    <p>
      На сайте могут содержаться ссылки на другие сайты. Сайт не несет
      ответственности за содержание, качество и политику безопасности этих
      сайтов. Данное заявление о конфиденциальности относится только к
      информации, размещенной непосредственно на сайте.
    </p>
    <h2>Безопасность</h2>
    <p>
      Сайт обеспечивает безопасность учетной записи Пользователя от
      несанкционированного доступа.
    </p>
    <h2>Уведомления об изменениях</h2>
    <p>
      Сайт оставляет за собой право вносить изменения в Политику
      конфиденциальности без дополнительных уведомлений. Нововведения вступают в
      силу с момента их опубликования. Пользователи могут отслеживать изменения
      в Политике конфиденциальности самостоятельно.
    </p>
  </article>
</template>
<style>
article {
  max-width: 1200px;
  margin: 0 auto;
  padding:1rem;
}
</style>
